.Card {
  padding-bottom: var(
    --spacing-small
  ) !important; /* override eufemia-card padding */
}

.Divider {
  color: var(--color-black-8);
}

.no-description {
  color: var(--color-black-55);
  font-style: italic;
}
